import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import jwtDecode from 'jwt-decode'
import { navigate } from 'gatsby'

const TokenContext = createContext({
  isAuthVerified: false,
  authToken: null,
  isFunctionVerified: false,
  functionToken: null,
  verifyAuthToken: async () => false,
  verifyFunctionToken: async () => false,
  logout: async () => {},
  refreshAuthTokens: async () => false,
  refreshFunctionTokens: async () => false,
  getAuthToken: async () => null,
  getFunctionToken: async () => null,
  loading: false,
  error: null,
})

export const useToken = () => useContext(TokenContext)

const getCookie = (name) => {
  const nameEQ = `${name}=`
  const ca = document.cookie.split(';').map((c) => c.trim())
  for (let i = 0; i < ca.length; i++) {
    if (ca[i].indexOf(nameEQ) === 0)
      return decodeURIComponent(ca[i].substring(nameEQ.length))
  }
  return null
}

export const TokenProvider = ({ children }) => {
  const [isAuthVerified, setIsAuthVerified] = useState(false)
  const [authToken, setAuthToken] = useState(null)
  const [isFunctionVerified, setIsFunctionVerified] = useState(false)
  const [functionToken, setFunctionToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [csrfToken, setCsrfToken] = useState('')
  const refreshAuthPromiseRef = useRef(null)
  const refreshFunctionPromiseRef = useRef(null)
  const csrfFetchPromiseRef = useRef(null)

  const fetchCsrfToken = useCallback(async () => {
    setLoading(true)
    if (csrfToken || getCookie('x-csrf-token')) {
      return csrfToken || getCookie('x-csrf-token')
    }

    if (csrfFetchPromiseRef.current) {
      return csrfFetchPromiseRef.current
    }

    csrfFetchPromiseRef.current = (async () => {
      try {
        const response = await fetch('/api/csrf-token', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const data = await response.json()
        setCsrfToken(data.token)
        return data.token
      } catch (error) {
        setError('Failed to fetch CSRF token.')
        return null
      } finally {
        csrfFetchPromiseRef.current = null
        setTimeout(() => {
          setLoading(false)
        }, 500)
      }
    })()

    return csrfFetchPromiseRef.current
  }, [])

  const isTokenExpired = useCallback((token, threshold = 60) => {
    if (!token) return true
    try {
      const decoded = jwtDecode(token)
      const currentTime = Date.now() / 1000
      return decoded.exp < currentTime + threshold
    } catch (e) {
      return true
    }
  }, [])

  const makePostRequest = useCallback(
    async (url, body) => {
      const cookieCsrfToken = getCookie('x-csrf-token')
      const tokenToUse = csrfToken || cookieCsrfToken
      if (!tokenToUse) {
        setError('CSRF token not available.')
        return null
      }

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': tokenToUse,
          },
          body: JSON.stringify(body),
          credentials: 'include',
        })

        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error || 'Request failed.')
        }

        const data = await response.json()
        return data
      } catch (err) {
        setError(err.message || 'An error occurred during the request.')
        return null
      }
    },
    [csrfToken]
  )

  const logout = useCallback(async () => {
    setIsAuthVerified(false)
    setIsFunctionVerified(false)
    setAuthToken(null)
    setFunctionToken(null)
    try {
      const data = await makePostRequest('/api/logout', {})
      if (data) {
        navigate('/login-form', { replace: true })
      }
    } catch (error) {
      setError('Logout failed.')
    }
  }, [makePostRequest])

  const verifyAuthToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/auth-verify-token', {
          tokenType: 'auth',
          token,
        })
        if (data && data.token) {
          setAuthToken(token)
          setIsAuthVerified(true)
          const firstTokenUse = data.firstUse
          if (firstTokenUse) {
            return { token: data.token, firstUse: firstTokenUse }
          }
          return data.token
        }
        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      } catch (err) {
        setError(
          err.message || 'An error occurred during auth token verification.'
        )
        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      }
    },
    [makePostRequest]
  )

  const verifyFunctionToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/function-verify-token', {
          tokenType: 'function',
          token,
        })

        if (data && data.token) {
          setFunctionToken(token)
          setIsFunctionVerified(true)
          return data.token
        }
        setIsFunctionVerified(false)
        setFunctionToken(null)
        return false
      } catch (err) {
        setError(
          err.message || 'An error occurred during function token verification.'
        )
        setIsFunctionVerified(false)
        setFunctionToken(null)
        return false
      }
    },
    [makePostRequest]
  )

  const refreshAuthTokens = useCallback(async () => {
    if (refreshAuthPromiseRef.current) {
      return refreshAuthPromiseRef.current
    }

    refreshAuthPromiseRef.current = (async () => {
      try {
        const data = await makePostRequest('/api/auth-refresh-token', {
          tokenType: 'auth',
          token: authToken,
        })

        if (data && data.authToken) {
          setAuthToken(data.authToken)
          setIsAuthVerified(true)
          return true
        }

        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      } catch (err) {
        setError(err.message || 'An error occurred during auth token refresh.')
        setIsAuthVerified(false)
        setAuthToken(null)
        return false
      } finally {
        refreshAuthPromiseRef.current = null
      }
    })()

    return refreshAuthPromiseRef.current
  }, [makePostRequest, authToken])

  const refreshFunctionTokens = useCallback(async () => {
    if (refreshFunctionPromiseRef.current) {
      return refreshFunctionPromiseRef.current
    }

    refreshFunctionPromiseRef.current = (async () => {
      try {
        const data = await makePostRequest('/api/function-refresh-token', {
          tokenType: 'function',
          token: functionToken,
        })

        if (data && data.functionToken) {
          setFunctionToken(data.functionToken)
          setIsFunctionVerified(true)
          return true
        }

        setIsFunctionVerified(false)
        setFunctionToken(null)
        return false
      } catch (err) {
        setError(
          err.message || 'An error occurred during function token refresh.'
        )
        setIsFunctionVerified(false)
        setFunctionToken(null)
        return false
      } finally {
        refreshFunctionPromiseRef.current = null
      }
    })()

    return refreshFunctionPromiseRef.current
  }, [makePostRequest, functionToken])

  const getAuthToken = useCallback(async () => {
    setError(null)

    if (authToken && isAuthVerified && !isTokenExpired(authToken)) {
      return authToken
    }

    if (authToken && isAuthVerified && isTokenExpired(authToken)) {
      const refreshed = await refreshAuthTokens()
      if (refreshed && authToken && !isTokenExpired(authToken)) {
        return authToken
      }
    }

    if (authToken && !isAuthVerified) {
      const verified = await verifyAuthToken(authToken)
      if (verified) {
        return authToken
      }
    }

    const refreshed = await refreshAuthTokens()
    if (refreshed) {
      return authToken
    }
    return null
  }, [
    authToken,
    isAuthVerified,
    verifyAuthToken,
    refreshAuthTokens,
    isTokenExpired,
  ])

  const getFunctionToken = useCallback(async () => {
    setError(null)

    if (functionToken && isFunctionVerified && !isTokenExpired(functionToken)) {
      return functionToken
    }

    if (functionToken && isFunctionVerified && isTokenExpired(functionToken)) {
      const refreshed = await refreshFunctionTokens()
      if (refreshed && functionToken && !isTokenExpired(functionToken)) {
        return functionToken
      }
    }

    if (functionToken && !isFunctionVerified) {
      const verified = await verifyFunctionToken(functionToken)
      if (verified) {
        return functionToken
      }
    }

    const refreshed = await refreshFunctionTokens()
    if (refreshed) {
      return functionToken
    }

    return null
  }, [
    functionToken,
    isFunctionVerified,
    verifyFunctionToken,
    refreshFunctionTokens,
    isTokenExpired,
  ])

  useEffect(() => {
    let isMounted = true

    const initializeCsrf = async () => {
      const token = await fetchCsrfToken()
      if (!token) {
        setLoading(false)
        return
      }

      if (isMounted) {
        setLoading(false)
      }
    }

    initializeCsrf()

    return () => {
      isMounted = false
    }
  }, [])

  const contextValue = {
    isAuthVerified,
    authToken,
    isFunctionVerified,
    functionToken,
    verifyAuthToken,
    verifyFunctionToken,
    logout,
    refreshAuthTokens,
    refreshFunctionTokens,
    getAuthToken,
    getFunctionToken,
    loading,
    error,
  }

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  )
}
