import { useState, useCallback, useMemo } from 'react'

const useFormValidation = (formHTML, formValues) => {
  const [fieldErrors, setFieldErrors] = useState({})

  const freeEmailDomains = [
    'gmail.com',
    'yahoo.com',
    'ymail.com',
    'rocketmail.com',
    'outlook.com',
    'hotmail.com',
    'live.com',
    'msn.com',
    'aol.com',
    'protonmail.com',
    'icloud.com',
    'me.com',
    'mac.com',
    'zoho.com',
    'mail.com',
    'gmx.com',
    'gmx.de',
    'web.de',
    'yandex.com',
    'yandex.ru',
    'tutanota.com',
    'fastmail.com',
    'rediffmail.com',
    'lycos.com',
    'juno.com',
    'naver.com',
    'daum.net',
    'o2.co.uk',
    'virginmedia.com',
    'blueyonder.co.uk',
    'talktalk.net',
    'btinternet.com',
    'wanadoo.fr',
    'orange.fr',
    'free.fr',
    'sfr.fr',
    'laposte.net',
    'libero.it',
    'tiscali.it',
    'virgilio.it',
    'alice.it',
    'tin.it',
    'seznam.cz',
    'centrum.cz',
    'post.cz',
    'volny.cz',
    'atlas.cz',
    'optonline.net',
    'earthlink.net',
    'comcast.net',
    'verizon.net',
    'att.net',
    'cox.net',
    'charter.net',
    'sbcglobal.net',
  ]

  const validationRules = useMemo(() => {
    const rules = {}

    if (!formHTML) return rules

    if (typeof window === 'undefined') {
      return rules
    }

    try {
      const parser = new DOMParser()
      const doc = parser.parseFromString(formHTML, 'text/html')
      const fields = doc.querySelectorAll('input, select, textarea')

      fields.forEach((field) => {
        const fieldId = field.id
        if (!fieldId) return

        if (!rules[fieldId]) {
          rules[fieldId] = {}
        }

        const title = field.getAttribute('title') || ''
        if (!rules[fieldId]) {
          rules[fieldId] = {}
        }

        // Zip Code Validation
        if (
          title.toLowerCase().includes('zip code' || 'postal code' || 'zipcode')
        ) {
          rules[fieldId].regex = /^\d{5}(-\d{4})?$/
          rules[fieldId].errorMessage = 'Invalid zip code format.'
        }

        // E-mail Validation
        if (title.toLowerCase().includes('e-mail' || 'email')) {
          rules[fieldId].regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          rules[fieldId].errorMessage = 'Invalid email format.'
          rules[fieldId].isEmail = true
        }

        // Phone Number Validation
        if (title.toLowerCase().includes('phone' || 'telephone')) {
          rules[fieldId].regex =
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
          rules[fieldId].errorMessage = 'Invalid phone number format.'
        }

        const isRequired =
          field.hasAttribute('required') ||
          field.getAttribute('aria-required') === 'true'
        if (isRequired) {
          rules[fieldId].required = true
          rules[fieldId].errorMessage =
            rules[fieldId].errorMessage || 'This field is required.'
        }

        const minLengthAttr = field.getAttribute('minlength')
        if (minLengthAttr) {
          rules[fieldId].minLength = parseInt(minLengthAttr, 10)
        }

        const maxLengthAttr = field.getAttribute('maxlength')
        if (maxLengthAttr) {
          rules[fieldId].maxLength = parseInt(maxLengthAttr, 10)
        }
      })
    } catch (error) {
      return error
    }
    return rules
  }, [formHTML])

  const validateField = useCallback(
    (fieldId, fieldValue) => {
      const rules = validationRules[fieldId]
      if (!rules) return { hasError: false, error: '' }

      const value = fieldValue?.toString().trim() || ''
      let error = ''

      if (rules.required && value === '') {
        error = 'This field is required.'
      } else if (rules.minLength && value.length < rules.minLength) {
        error = `Minimum length is ${rules.minLength}.`
      } else if (rules.maxLength && value.length > rules.maxLength) {
        error = `Maximum length is ${rules.maxLength}.`
      } else if (rules.regex) {
        if (!rules.regex.test(value)) {
          error = rules.errorMessage || 'Invalid format.'
        }
      }

      if (rules.isEmail && !error) {
        const domain = value.split('@')[1]?.toLowerCase()
        if (domain && freeEmailDomains.includes(domain)) {
          error = `Emails from '${domain}' are not allowed.`
        }
      }

      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [fieldId]: error,
      }))

      return { hasError: !!error, error }
    },
    [validationRules]
  )

  const validateAllFields = useCallback(() => {
    const newFieldErrors = {}
    let hasError = false

    for (const fieldId in validationRules) {
      const rules = validationRules[fieldId]
      const value = formValues[fieldId]?.toString().trim() || ''
      let error = ''

      if (rules.required && value === '') {
        error = 'This field is required.'
      } else if (rules.minLength && value.length < rules.minLength) {
        error = `Minimum length is ${rules.minLength}.`
      } else if (rules.maxLength && value.length > rules.maxLength) {
        error = `Maximum length is ${rules.maxLength}.`
      } else if (rules.regex) {
        if (!rules.regex.test(value)) {
          error = rules.errorMessage || 'Invalid format.'
        }
      }

      if (rules.isEmail && !error) {
        const domain = value.split('@')[1]?.toLowerCase()
        if (domain && freeEmailDomains.includes(domain)) {
          error = `Email addresses from the '${domain}' domain are not allowed.`
        }
      }

      if (error) {
        newFieldErrors[fieldId] = error
        hasError = true
      }
    }

    setFieldErrors(newFieldErrors)
    return { hasError, newFieldErrors }
  }, [formValues, validationRules])

  const clearErrors = useCallback(() => {
    setFieldErrors({})
  }, [])

  const hasErrors = useMemo(
    () => Object.keys(fieldErrors).length > 0,
    [fieldErrors]
  )

  return {
    fieldErrors,
    validateField,
    validateAllFields,
    setFieldErrors,
    clearErrors,
    hasErrors,
    validationRules,
  }
}

export default useFormValidation
