import React from 'react'
import PropTypes from 'prop-types'
import FormComponent from './FormComponent'
import ErrorBoundary from './ErrorBoundary'

const FormAssemblyForm = React.memo(({ formId, customValidation, isModal }) => (
  <ErrorBoundary>
    <FormComponent
      formId={formId}
      customValidation={customValidation}
      isModal={isModal}
    />
  </ErrorBoundary>
))

FormAssemblyForm.propTypes = {
  formId: PropTypes.number.isRequired,
  customValidation: PropTypes.string,
  isModal: PropTypes.bool,
}

export default FormAssemblyForm
