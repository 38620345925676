import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useTransition,
} from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Location } from '@reach/router'
import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'
import IframePopup from '../components/IframePopup'
import { AuthContext } from './context/AuthContext'
import { ONE_HOUR_COOKIE_EXPIRE, USER_EXPERIENCES } from './constants'
import { AttestationModal } from '../components/attestationModal'
import { useToken } from './context/TokenContext'
import FormModal from '../components/Modal/formModal'

function useSendUserExperienceGtm({ allRoutes, pathname, isInsight }) {
  useEffect(() => {
    const isWindowDefined = typeof window !== 'undefined'

    if (isWindowDefined && isInsight) {
      const onlyInsightsRoutes = [
        ...(allRoutes?.allContentstackInsightArticle?.nodes ?? []),
        ...(allRoutes?.allContentstackInsightWebinar?.nodes ?? []),
      ]

      const currentInsight = onlyInsightsRoutes.find(({ url }) =>
        pathname.includes(url)
      )

      const currentInsightExperience =
        currentInsight?.user_experience?.experience

      if (currentInsightExperience) {
        window.dataLayer.push({
          userExperience: currentInsightExperience,
        })
      }
    }
  }, [isInsight, pathname, allRoutes])
}

const skipLoginPaths = ['/contact-us/institutional']

const GatedContentHandler = ({ children, router }) => {
  const [openIframe, setIsOpenIframe] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [isPending, startTransition] = useTransition()

  const allRoutes = useStaticQuery(graphql`
    query {
      allContentstackContactUsLoggedIn(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightArticle {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightWebinar {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackLandingPage(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
    }
  `)

  const [cookies] = useCookies(['harborCookie'])
  const { harborCookie } = cookies

  const authCtx = useContext(AuthContext)
  let { pathname, search } = router.location

  const isInsight = useMemo(() => pathname.startsWith('/insights/'), [pathname])

  useSendUserExperienceGtm({ allRoutes, pathname, isInsight })

  const privatePages = useMemo(() => {
    return Object.keys(allRoutes)
      .flatMap((key) => allRoutes[key].nodes)
      .filter(
        (route) =>
          route.user_experience.experience ===
          USER_EXPERIENCES.INSTITUTIONAL_ONLY
      )
      .map((route) => route.url)
  }, [allRoutes])

  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, -1)
  }

  const isPrivatePage = privatePages.includes(pathname)
  const isSignupViewed = Cookies.get('signupViewed') === 'true'

  const queryParams = new URLSearchParams(search)
  const modalKey = queryParams.get('form')

  const formIds = {
    register: 30,
    login: 29,
  }

  const formId = formIds[modalKey]

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (formId) {
      startTransition(() => {
        setModalOpen(true)
      })
    } else {
      startTransition(() => {
        setModalOpen(false)
      })
    }
  }, [formId, startTransition])

  const handleModalClose = () => {
    startTransition(() => {
      setModalOpen(false)
    })

    const queryParams = new URLSearchParams(router.location.search)
    queryParams.delete('form')
    const newSearch = queryParams.toString()
    const newUrl = router.location.pathname + (newSearch ? `?${newSearch}` : '')

    navigate(newUrl, { replace: true })
  }

  const { authToken, getAuthToken } = useToken()

  useEffect(() => {
    if (isPrivatePage && !isSignupViewed) {
      const [visitorId, prospectId, viewCount, sfContactId, sfOwnerId] =
        harborCookie?.split('|') ?? []

      if (
        !prospectId ||
        !sfContactId ||
        !sfOwnerId ||
        prospectId === 'null' ||
        sfContactId === 'null' ||
        sfOwnerId === 'null'
      ) {
        if (!skipLoginPaths.includes(pathname)) {
          const fetchHarborCookie = async () => {
            try {
              let obtainedToken = authToken
              if (!obtainedToken) {
                obtainedToken = await getAuthToken()
                if (!obtainedToken) {
                  return
                }
              }

              const res = await fetch('/api/harbor-cookie', {
                credentials: 'same-origin',
              })

              if (!res.ok) {
                throw new Error('Failed to fetch harbor-cookie')
              }

              const data = await res.json()

              if (!data) {
                Cookies.set('signupViewed', 'true', {
                  expires: ONE_HOUR_COOKIE_EXPIRE,
                })
                window.dataLayer.push({
                  signUpPopupViewed: 'User reached 10 articles viewed',
                })
                startTransition(() => {
                  setIsOpenIframe(true)
                })
              } else {
                authCtx.actions.tryLogin()
              }
            } catch (error) {
              console.error('Error fetching harbor-cookie:', error)
            }
          }

          fetchHarborCookie()
        }
      }
    }
  }, [
    pathname,
    isPrivatePage,
    isSignupViewed,
    harborCookie,
    authCtx.actions,
    authToken,
    getAuthToken,
    startTransition,
  ])

  const handleCloseIframe = () => {
    startTransition(() => {
      setIsOpenIframe(false)
    })
  }

  const shouldShowAttestationModal =
    !isInsight &&
    isPrivatePage &&
    !authCtx?.state?.isLoggedIn &&
    authCtx?.state.userExperience !== USER_EXPERIENCES.INSTITUTIONAL_ONLY

  const handleAttestationClose = () => {
    navigate('/')
  }

  return (
    <>
      {isClient && openIframe && (
        <IframePopup
          src="https://forms.harborcapital.com/f/signup"
          isOpen={openIframe}
          onClose={handleCloseIframe}
        />
      )}
      <AttestationModal
        open={shouldShowAttestationModal}
        onCloseCancel={handleAttestationClose}
      />
      {children}
      {isClient && isModalOpen && (
        <FormModal
          onClose={handleModalClose}
          isOpen={isModalOpen}
          formId={formId}
          customValidation={modalKey}
          isModal
        />
      )}
      {isPending && <div>Updating Modal...</div>}
    </>
  )
}

const withLocation = ({ children }) => {
  return (
    <Location>
      {(location) => (
        <GatedContentHandler children={children} router={location} />
      )}
    </Location>
  )
}

export default React.memo(withLocation)
